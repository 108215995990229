import React, { FC } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

import Button from '../Button/Button'

interface InsertLinkDialogProps {
  dialogOpen: boolean
  handleSubmit: (url: string) => void
  handleClose: () => void
  isImage?: boolean
}

const InsertLinkDialog: FC<InsertLinkDialogProps> = ({
  dialogOpen,
  handleSubmit,
  handleClose,
  isImage = false,
}) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          const formData = new FormData(event.currentTarget)
          const formJson = Object.fromEntries((formData as any).entries())
          const url = formJson.url
          handleSubmit(url)
        },
      }}
    >
      <DialogTitle className="px-4">
        {isImage ? 'Image' : 'Hyperlink'}
      </DialogTitle>
      <DialogContent className="p-4">
        <TextField
          required
          id="url"
          name="url"
          placeholder={isImage ? 'Insert image url' : 'Insert hyperlink url'}
          type="text"
          fullWidth
          size="small"
          className="w-[400px]"
        />
      </DialogContent>
      <DialogActions className="p-4 pt-0">
        <Button
          onClick={handleClose}
          variant="text"
          color="secondary"
          fullWidth={false}
        >
          Cancel
        </Button>
        <Button fullWidth={false} type="submit">
          Insert
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InsertLinkDialog
