import { FC, useEffect, useState } from 'react'

import { AnyExtension } from '@tiptap/core'
import { History } from '@tiptap/extension-history'
import { Image } from '@tiptap/extension-image'
import { Link } from '@tiptap/extension-link'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Underline } from '@tiptap/extension-underline'
import { TextSelection } from '@tiptap/pm/state'
import { useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import {
  MenuButtonAddImage,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonStrikethrough,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditorProvider,
  RichTextField,
  RichTextReadOnly as ReadOnly,
} from 'mui-tiptap'
import { useDebounceValue } from 'usehooks-ts'

import InsertLinkDialog from './InsertLinkDialog'

interface RichTextEditorProps {
  id?: string
  name?: string
  defaultValue: string
  disabled?: boolean
  onChange?: (param?: string) => void
  debounce?: number
  readOnly?: boolean
  className?: string
  onFocus?: () => void
}

export const RichTextExtensions = [
  StarterKit.configure({
    orderedList: {
      HTMLAttributes: {
        class: 'pl-5',
      },
    },
    bulletList: {
      HTMLAttributes: {
        class: 'pl-5',
      },
    },
    history: false,
  }),
  History,
  Underline,
  Placeholder,
  Image.configure({
    allowBase64: true,
  }),
  Link.configure({
    openOnClick: false,
  }),
]

const RichTextEditor: FC<RichTextEditorProps> = ({
  id,
  name,
  defaultValue,
  disabled = false,
  onChange = () => null,
  debounce = 0,
  readOnly = false,
  className = '',
  onFocus = () => null,
}) => {
  // Insert Link Dialog States
  const [dialogState, setDialogState] = useState<'link' | 'image'>(null)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [richTextEditorValue, setRichTextEditorValue] = useState<string>(
    defaultValue || '',
  )

  const [debouncedValue] = useDebounceValue(richTextEditorValue, debounce)

  // Send Back when debounce value changes
  useEffect(() => {
    if (debouncedValue) {
      onChange(debouncedValue)
    }
  }, [debouncedValue])

  // Init the editor
  const editor = useEditor({
    extensions: RichTextExtensions as AnyExtension[],
    content: richTextEditorValue,
    onUpdate: ({ editor }) => {
      setRichTextEditorValue(editor.getHTML())
    },
    editorProps: {
      attributes: {
        id,
        name,
        'data-testid': 'rich-text-editor',
        class: `min-h-[200px] overflow-y-scroll w-full ${className}`,
      },
    },
    onFocus: () => {
      onFocus()
    },
  })

  // disable editor editing else enable
  if (editor && disabled) {
    editor.setOptions({ editable: false })
  } else {
    editor?.setOptions({ editable: true })
  }

  // Open Dialog in Link State
  const handleLinkDialogOpen = () => {
    setDialogState('link')
    setDialogOpen(true)
  }

  // Open Dialog in Image State
  const handleImageDialogOpen = () => {
    setDialogState('image')
    setDialogOpen(true)
  }

  // Close Dialog
  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  // Handle the Dialog form submit
  const handleDialogSubmit = (url: string) => {
    if (dialogState === 'image') {
      editor.chain().focus().setImage({ src: url }).run()
    }

    if (dialogState === 'link') {
      let cleanedUrl = url

      // If not a relative path, make sure https:// is at the start
      if (
        url.charAt(0) !== '/' &&
        !url.startsWith('https://') &&
        !url.startsWith('http://')
      ) {
        cleanedUrl = 'https://' + url
      }

      editor.chain().focus().setLink({ href: cleanedUrl }).run()
    }

    handleDialogClose()
  }

  useEffect(() => {
    if (editor && defaultValue !== editor.getHTML()) {
      // Save cursor position
      const { from, to } = editor.state.selection

      // Update content
      editor.commands.setContent(defaultValue)

      // Restore cursor position
      const newFrom = Math.min(from, editor.state.doc.content.size)
      const newTo = Math.min(to, editor.state.doc.content.size)
      const textSelection = new TextSelection(
        editor.state.doc.resolve(newFrom),
        editor.state.doc.resolve(newTo),
      )
      editor.view.dispatch(editor.state.tr.setSelection(textSelection))
    }
  }, [defaultValue, editor])

  if (readOnly) {
    return (
      <ReadOnly
        content={defaultValue}
        extensions={RichTextExtensions as AnyExtension[]}
        editorProps={{
          attributes: {
            'data-testid': 'rich-text-editor',
          },
        }}
      />
    )
  } else
    return (
      <>
        <RichTextEditorProvider editor={editor}>
          <RichTextField
            controls={
              <MenuControlsContainer>
                <MenuSelectHeading />
                <MenuDivider />
                <MenuButtonBold />
                <MenuButtonItalic />
                <MenuButtonUnderline />
                <MenuButtonStrikethrough />
                <MenuDivider />
                <MenuButtonBulletedList />
                <MenuButtonOrderedList />
                <MenuButtonEditLink
                  onClick={handleLinkDialogOpen}
                  aria-label="Insert link"
                />
                <MenuButtonAddImage
                  onClick={handleImageDialogOpen}
                  aria-label="Insert image"
                />
                <MenuDivider />
                <MenuButtonUndo
                  onClick={() => editor.chain().focus().undo().run()}
                  disabled={editor && !editor.can().undo()}
                />
                <MenuButtonRedo
                  onClick={() => editor.chain().focus().redo().run()}
                  disabled={editor && !editor.can().redo()}
                />
              </MenuControlsContainer>
            }
          />
        </RichTextEditorProvider>
        <InsertLinkDialog
          handleSubmit={handleDialogSubmit}
          handleClose={handleDialogClose}
          isImage={dialogState === 'image'}
          dialogOpen={dialogOpen}
        />
      </>
    )
}
export default RichTextEditor
